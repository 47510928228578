import React, {Component} from 'react';
import {BrowserRouter as Router, Routes, Route, Link, useLocation} from 'react-router-dom';
import {Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel} from 'react-accessible-accordion';

import Home from './components/Home';
import About from './components/About';
import IncentiveAutomation from './components/products/IncentiveAutomation';
import AcquisitionOnboarding from './components/products/AcquisitionOnboarding';
import PerformanceManagement from './components/products/PerformanceManagement';
import SalesCustomerService from './components/solutions/SalesCustomerService';
import Finance from './components/solutions/Finance';
import RevenueOperations from './components/solutions/RevenueOperations';
import BusinessHeadsCXO from './components/solutions/BusinessHeadsCXO';
import BookADemo from './components/BookADemo';

import logo from './assets/logo.svg';
import logoDark from './assets/logo-dark.svg';
import navRightArrow from './assets/nav-right-arrow.svg';
import fbIcon from './assets/fb-icon.svg';
import twIcon from './assets/tw-icon.svg';
import lnIcon from './assets/ln-icon.svg';
import line from './assets/line.svg';
import fbIconB from './assets/fb-icon-b.svg';
import twIconB from './assets/tw-icon-b.svg';
import lnIconB from './assets/ln-icon-b.svg';
import arrowWhiteDown from './assets/arrow-white-down.svg';
import privacypolicypdf from './assets/docs/Ancora-Privacy-Policy.pdf';

export const withLocation = (Component : Component) => {
  return props => <Component {...props} location={useLocation()}/>;
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fixedNav: '',
      isReady: '',
      isMobileMenuOpen: '',
      isAbout: '',
      isBookDemo: ''
    };
    this.updateScroll = this.updateScroll.bind(this);
    this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
  }
  componentDidMount() {
    setTimeout(() => this.setState({isReady: 'ready'}), 100);
    window.addEventListener('scroll', this.updateScroll);
  }

  static getDerivedStateFromProps(props, state) {
    //Add class to nav on About Page
    if (props.location.pathname === '/about') {
      return {isAbout: 'about'};
    } else if (props.location.pathname === '/book-a-demo') {
      return {isBookDemo: 'book'}
    } else {
      return {isAbout: '', isBookDemo: ''};
    }
  }
  
  render() {
    return (
      <div>
        <nav className={this.state.fixedNav + ' ' + this.state.isReady + ' ' + this.state.isMobileMenuOpen + ' ' + this.state.isAbout + ' ' + this.state.isBookDemo}>
          <div className="container">
            <Link to="/" className="logo" onClick={() => {
                this.setState({isMobileMenuOpen: ''});
                document.body.classList.remove('noscroll');
              }}>
              <img src={logo} className="logo-light"/>
              <img src={logoDark} className="logo-dark"/>
            </Link>
            <ul className="menu">
              <li>
                <span>Products</span>
                <ul>
                  <li>
                    <Link to="products/incentive-automation">Incentive Automation<img src={navRightArrow}/>
                      <span className="text-gradient">100% Accurate &amp; Transparent Payouts</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="products/acquisition-onboarding">Acquisition &amp; Onboarding<img src={navRightArrow}/>
                      <span className="text-gradient">Boost Your Market Reach</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="products/performance-management">Performance Management<img src={navRightArrow}/>
                      <span className="text-gradient">Increase Workforce Productivity</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <span>Solutions</span>
                <ul className="solutions-menu">
                  <li>
                    <Link to="solutions/sales-customer-service">Sales &amp; Customer Service<img src={navRightArrow}/>
                      {/* <span className="text-gradient">Lorem ipsum dolor sit amet</span> */}
                    </Link>
                  </li>
                  <li>
                    <Link to="solutions/finance">Accounting &amp; Finance<img src={navRightArrow}/>
                      {/* <span className="text-gradient">Lorem ipsum dolor sit amet</span> */}
                    </Link>
                  </li>
                  <li>
                    <Link to="solutions/revenue-operations">Revenue / Operations<img src={navRightArrow}/>
                      {/* <span className="text-gradient">Lorem ipsum dolor sit amet</span> */}
                    </Link>
                  </li>
                  <li>
                    <Link to="solutions/business-heads-cxo">Business Heads &amp; CXO’s<img src={navRightArrow}/>
                      {/* <span className="text-gradient">Lorem ipsum dolor sit amet</span> */}
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <span>Industries</span>
                <ul>
                  <li>
                    <Link to="#">{"Coming Soon..."}
                      {/* <span className="text-gradient">100% Accurate &amp; Transparent Payouts</span> */}
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/about">About Us</Link>
              </li>
            </ul>
            <div className="demo-box">
            {/* <Link to="/book-a-demo" className="demo">Book a demo</Link> */}
              <a href="https://calendly.com/rahul_ancora/30min" target="_blank" className="demo">Book a demo</a>
            </div>
            <button className={'mobile-btn ' + this.state.isMobileMenuOpen} onClick={this.toggleMobileMenu}>
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div>
          <div className="container">
            <div className={'mobile-menu ' + this.state.isMobileMenuOpen}>
              <p>Grow your business faster. With state of the art incentive &amp; commission automation.</p>
              <Accordion>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Products
                      <img src={arrowWhiteDown}/>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <Link to="products/incentive-automation" onClick={this.toggleMobileMenu}>Incentive Automation</Link>
                    <Link to="products/acquisition-onboarding" onClick={this.toggleMobileMenu}>Acquisition &amp; Onboarding</Link>
                    <Link to="products/performance-management" onClick={this.toggleMobileMenu}>Performance Management</Link>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Solutions
                      <img src={arrowWhiteDown}/>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <Link to="solutions/sales-customer-service" onClick={this.toggleMobileMenu}>Sales &amp; Customer Service</Link>
                    <Link to="solutions/finance" onClick={this.toggleMobileMenu}>Accounting &amp; Finance</Link>
                    <Link to="solutions/revenue-operations" onClick={this.toggleMobileMenu}>Revenue / Operations</Link>
                    <Link to="solutions/business-heads-cxo" onClick={this.toggleMobileMenu}>Business Heads &amp; CXO’s</Link>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
              <div className="links-container">
                <div className="links">
                  <a href="#">FAQs</a>
                  <a href="https://www.linkedin.com/company/ancorafintech/jobs" target="_blank">Careers</a>
                  <Link to="/about" onClick={this.toggleMobileMenu}>About Us</Link>
                </div>
                <div className="social">
                  {/* <a href="#"><img src={fbIconB}/></a> */}
                  <a href="https://www.linkedin.com/company/ancorafintech" target="_blank"><img src={lnIcon}/></a>
                  <a href="https://twitter.com/ancoratechnolo1" target="_blank"><img src={twIcon}/></a>
                </div>
              </div>
              <div className="copy">&copy; Ancora Technologies Pvt. Ltd. &nbsp; All Rights Reserved</div>
            </div>
          </div>
        </nav>
        <Routes>
          <Route path="/book-a-demo" element={<BookADemo />}/>
          <Route path="/solutions/business-heads-cxo" element={<BusinessHeadsCXO />}/>
          <Route path="/solutions/revenue-operations" element={<RevenueOperations />}/>
          <Route path="/solutions/finance" element={<Finance />}/>
          <Route path="/solutions/sales-customer-service" element={<SalesCustomerService />}/>
          <Route path="/products/performance-management" element={<PerformanceManagement />}/>
          <Route path="/products/acquisition-onboarding" element={<AcquisitionOnboarding />}/>
          <Route path="/products/incentive-automation" element={<IncentiveAutomation />}/>
          <Route path="/about" element={<About />}/>
          <Route path="/" element={<Home />}/>
        </Routes>
        <footer className={this.state.isBookDemo}>
          <div className="container">
            <div className="col-40p">
              <img src={logo} className="logo"/>
              <p className="below-logo">ANCORA helps organizations simplify complex processes and tasks across the sales &amp; other customer-facing functions, and provides digital solutions for sales enablement, channel activation & management, workforce engagement and incentive automation.</p>
            </div>
            <div className="col-60p">
              <ul>
                <li>
                  <h6>Company Links:</h6>
                  <Link to="about">About Us</Link>
                  <a href="https://www.linkedin.com/company/ancorafintech/jobs" target="_blank">Careers</a>
                  <a href="#faq">FAQ’s</a>
                  <a href={privacypolicypdf} target="_blank">Privacy Policy</a>
                </li>
                <li>
                  <h6>Products:</h6>
                  <Link to="products/incentive-automation">Incentive Automation</Link>
                  <Link to="products/acquisition-onboarding">Acquisition &amp; Onboarding</Link>
                  <Link to="products/performance-management">Performance Management</Link>
                </li>
                <li>
                  <h6>Solutions:</h6>
                  <Link to="solutions/sales-customer-service">Sales &amp; Customer Service</Link>
                  <Link to="solutions/finance">Accounting &amp; Finance</Link>
                  <Link to="solutions/revenue-operations">Revenue / Operations</Link>
                  <Link to="solutions/business-heads-cxo">Business Heads &amp; CXO’s</Link>
                </li>
              </ul>
              <div className="social">
                {/* <a href="#"><img src={fbIcon}/></a> */}
                <a href="https://www.linkedin.com/company/ancorafintech" target="_blank"><img src={lnIcon}/></a>
                <a href="https://twitter.com/ancoratechnolo1" target="_blank"><img src={twIcon}/></a>
              </div>
            </div>
          </div>
          <div className="container">
            <img src={line} className="line"/>
          </div>
          <div className="container" id="faq">
            <div className="full-col faq">
              <h2>Frequently Asked Questions</h2>
              <h6>How does Ancora automate incentives?</h6>
              <p>Ancora’s incentive automation platform allows organizations to set tangible targets and quotas for their customer facing team, and define incentives or commissions linked to those targets. Once an employee achieves his / her target(s), the system automatically calculates the commission and provides real-time earnings visibility to the individual.</p>
              <h6>Our organization does set individual targets and quotas. Will the platform still be useful?</h6>
              <p>Yes, in absence of targets, you can still automate your commissions by simply defining the payouts rules and running the policy. The rules can vary from a flat payout percentage to highly complex incentive grids with 100s of variables.</p>
              <h6>Our organization keeps on changing the incentives schemes and payout cycles. Will the platform be able handle these changes?</h6>
              <p>Yes. Using the platform’s no-code Business Rule Engine, you can make any number of edits and make changes in the policy on-the-go. The system maintains an audit trail of changes and all previous versions of the schemes are available at all times.</p>
              <h6>Since our sales and customer service data is captured and maintained in different systems and formats, can the platform aggregate and structure this data?</h6>
              <p>Yes, Ancora’s incentive automation platform can seamless pull and structure the data multiple sources. While the platform has ready integrations with all leading CRMs and ERPs, it’s micro-services based architecture allows easy integrations with any other / custom-built systems. Data import through other channels like MS Excel, MySQL servers, is also available.</p>
              <h6>Our internal processes require multiple levels of approvals, manual adjustments and deviations. How will platform handle those issues?</h6>
              <p>Ancora’s no-code Workflow Engine allows organizations to create multiple custom workflows best suited to their needs. The platform also allows for manual adjustments & deviations, while taking care of all compliance issues through features like maker-checked and audit logs.</p>
              <h6>How will Ancora help us increase our through-put?</h6>
              <p>Ancora’s incentive gamification tools help increase workforce engagement and drive productivity. Tools like “performance dashboards” help front-line workers get real-time earnings visibility, while intelligent features like “milestone nudges” help them achieve their targets faster. Schedule a demo with our team to know more about Ancora’s gamification tools.</p>
              <h6>How does Ancora help with channel growth?</h6>
              <p>Ancora’s partner onboarding platform enables remote and paperless onboarding &amp; activation of new channels. Using the platform, organizations can automate & streamline the complete process, including data capture (OCR), data verification (GSTN APIs, PAN APIs, eKYC, Penny-Drop, Telecom APIs, etc.), hygiene checks(Negative Lists, Credit Bureaus, etc.), contracting (Smart Agreement & eSign) and activation (code creation).</p>
            </div>
          </div>
          <div className="container">
            <img src={line} className="line"/>
          </div>
          <div className="container">
            <div className="full-col copy">&copy; Ancora Technologies Pvt. Ltd. &nbsp; All Rights Reserved
              {/* <a href="#">Privacy &amp; Data Policy</a>
              <a href="#">Terms of Use</a> */}
            </div>
          </div>
        </footer>
        <footer className={'footer-mobile ' + this.state.isBookDemo}>
          <div className="container">
            <div className="copy">&copy; Ancora Technologies Pvt. Ltd. &nbsp; All Rights Reserved
              {/* <a href="#"><img src={fbIcon}/></a> */}
              <a href="https://twitter.com/ancoratechnolo1" target="_blank"><img src={twIcon}/></a>
              <a href="https://www.linkedin.com/company/ancorafintech" target="_blank"><img src={lnIcon}/></a>
            </div>
          </div>
          <a href="https://calendly.com/rahul_ancora/30min" target="_blank">Book A Demo</a>
        </footer>
      </div>
    );
  }
  updateScroll() {
    if (window.scrollY > 30) {
      this.setState({fixedNav: 'fix'});
    } else {
      this.setState({fixedNav: ''});
    }
    document.querySelectorAll('.listening').forEach(el => {
      if (el.getBoundingClientRect().bottom >= 0 && el.getBoundingClientRect().right >= 0 && el.getBoundingClientRect().top <= (window.innerHeight || document.documentElement.clientHeight) && el.getBoundingClientRect().left <= (window.innerWidth || document.documentElement.clientWidth))
        el.classList.add('reveal');
      }
    );
  }
  toggleMobileMenu() {
    this.setState({
      isMobileMenuOpen: this.state.isMobileMenuOpen === 'open'
        ? ''
        : 'open'
    });
    if (document.body.classList.contains('noscroll')) {
      document.body.classList.remove('noscroll');
    } else {
      document.body.classList.add('noscroll');
    }
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.updateScroll);
  }
}

export default withLocation(App);
