import React, {Component} from 'react';

import blueRound from '../assets/blue-round.svg';
import blueRoundL from '../assets/blue-round-l.svg';
import arrowUpLarge from '../assets/arrow-up-large.svg';

export default class Join extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isReady: false
    }
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({isReady: true})
    }, 100);
  }
  render() {
    return (
      <section className={'book-demo ' + (
          this.state.isReady
            ? 'ready'
            : ''
        )}>
        <div className="container">
          <div className="col-2">
            <h2 className="offset">Let’s get this conversation started.</h2>
            <p className="offset">Tell us a bit about yourself, and we’ll get in touch as soon as we can.</p>
          </div>
          <div className="col-2">
            <form>
              <div className="control offset">
                <input type="text" name="name" placeholder="Your Full Name" required="required"/>
              </div>
              <div className="control offset">
                <input type="email" name="email" placeholder="Your email address" required="required"/>
              </div>
              <div className="control offset">
                <input type="text" name="company" placeholder="Your Company Name" required="required"/>
              </div>
              <div className="control select offset">
                <select name="product" required="required">
                  <option disabled="disabled">Select a product for the demo</option>
                  <option>Sample</option>
                </select>
              </div>
              <div className="control offset">
                <textarea name="message" placeholder="Anything specific you’d like to share with us?" required="required"/>
              </div>
              <button type="submit">Submit Form</button>
            </form>
            <div className="copy">&copy; Ancora Technologies Pvt. Ltd. &nbsp; &nbsp; All Rights Reserved
              <a href="#">Privacy &amp; Data Policy</a>
              <a href="#">Terms of Use</a>
            </div>
          </div>
        </div>
        <div className="square"></div>
        <div className="square"></div>
        <div className="square"></div>
        <div className="square"></div>
      </section>
    )
  }
}
