import React, {Component} from 'react';

import Testimonial from '../Testimonials';

import incentiveAutomationLandingImage from '../../assets/incentive-automation-landing.jpg';
import arrowUpLarge from '../../assets/arrow-up-large.svg';
import productLogo1 from '../../assets/product-logo-1.png';
import productLogo2 from '../../assets/product-logo-2.png';
import productLogo3 from '../../assets/product-logo-3.png';
import productLogo4 from '../../assets/product-logo-4.png';
import laptop from '../../assets/laptop.png';
import productIA1 from '../../assets/product-ia-1.svg';
import productIA2 from '../../assets/product-ia-2.svg';
import productIA3 from '../../assets/product-ia-3.svg';
import productIA4 from '../../assets/product-ia-4.svg';
import productIA5 from '../../assets/product-ia-5.svg';
import productIA6 from '../../assets/product-ia-6.svg';
import productIA7 from '../../assets/product-ia-7.svg';
import plugLogo1 from '../../assets/plug-logo-1.png';
import plugLogo2 from '../../assets/plug-logo-2.png';
import plugLogo3 from '../../assets/plug-logo-3.png';
import plugLogo4 from '../../assets/plug-logo-4.png';
import plugLogo5 from '../../assets/plug-logo-5.png';
import plugLogo6 from '../../assets/plug-logo-6.png';

export default class IncentiveAutomation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isReady: false
    }
  }
  componentDidMount() {
    setTimeout(() => this.setState({isReady: 'ready'}), 100);
  }
  render() {
    return (
      <div className={'page-product ' + this.state.isReady}>
        <section className="section-landing">
          <div className="container">
            <div className="col-2">
              <h1 className="offset">100% Accuracy & Transparency</h1>
              <h5 className="offset">for a more engaged and motivated workforce</h5>
              <p className="offset">An end-to-end platform for seamlessly designing and executing incentive schemes & commission plans for all your sales and customer facing teams.</p>
            </div>
            <div className="col-2">
              <div className="spread offset">
                <div className="image">
                  <img src={incentiveAutomationLandingImage}/>
                </div>
              </div>
            </div>
          </div>
          <div className="flip-a"><img src={arrowUpLarge}/></div>
          <div className="flip-b"></div>
        </section>
        <section className="section-landing-mobile">
          <div className="container">
            <p className="listening">An end-to-end platform for seamlessly designing and executing incentive schemes & commission plans for all your sales and customer facing teams.</p>
          </div>
        </section>
        <section className="section-laptop">
          <div className="container">
            <div className="col-2">
              <h2 className="listening">Automated Incentives & Payouts</h2>
              <p className="listening">Free up your valuable time & resources with end-to-end commission automation.</p>
              <div className="points">
                <div>
                  <h3 className="listening">1.</h3>
                  <p className="listening">Create - Define highly complex and variable-drive commissions</p>
                </div>
                <div>
                  <h3 className="listening delay-0">2.</h3>
                  <p className="listening delay-0">Calculate - Automated calculations for real-time earnings visibility</p>
                </div>
                <div>
                  <h3 className="listening">3.</h3>
                  <p className="listening">Collaborate - Built-in workflows for special approvals & dispute-resolution</p>
                </div>
                <div>
                  <h3 className="listening delay-0">4.</h3>
                  <p className="listening delay-0">Compensate - Automated payments & recon through pre-defined payout cycles</p>
                </div>
              </div>
              {/* <p className="listening">Companies we helped...</p>
              <div className="logos">
                <img className="listening" src={productLogo1}/>
                <img className="listening delay-0" src={productLogo2}/>
                <img className="listening delay-1" src={productLogo3}/>
                <img className="listening delay-2" src={productLogo4}/>
              </div> */
              }
            </div>
            <div className="col-2 laptop">
              <div className="spread listening"></div>
              <img className="listening" src={laptop}/>
            </div>
          </div>
        </section>
        <section className="section-product">
          <div className="container">
            <div className="col-2">
              <div className="image listening"><img src={productIA1}/></div>
            </div>
            <div className="col-2">
              <h3 className="listening">No-code Commission Rule Engine</h3>
              <p className="listening">Design, edit and execute variable-based incentives based products, roles, geographies, teams and hierarchies. Create custom targets & quotes, win-backs, clawbacks, hero-brands, etc.</p>
            </div>
          </div>
          <div className="container">
            <div className="col-2">
              <div className="image listening"><img src={productIA2}/></div>
            </div>
            <div className="col-2">
              <h3 className="listening">Data Integrations</h3>
              <p className="listening">Ancora’s Incentive Automation platform seamlessly integrates with all leading CRM, HRMS and ERP systems, enabling automated data aggregation & real-time incentive calculation.</p>
            </div>
          </div>
          <div className="container">
            <div className="col-2">
              <div className="image listening"><img src={productIA3}/></div>
            </div>
            <div className="col-2">
              <h3 className="listening">Digital Workflow</h3>
              <p className="listening">The platform’s configurable workflow allows organisations to create rule & hierarchy-based workflows for exception handling, manual deviations, dispute resolution and payout approvals.</p>
            </div>
          </div>
          <div className="container">
            <div className="col-2">
              <div className="image listening"><img src={productIA4}/></div>
            </div>
            <div className="col-2">
              <h3 className="listening">Compliance &amp; Audit</h3>
              <p className="listening">Be audit-ready with comprehensive transaction and activity logs spanning historical commission plans, transaction-wise calculations & payouts, and all employee actions / deviations.</p>
            </div>
          </div>
          <div className="container">
            <div className="col-2">
              <div className="image listening"><img src={productIA5}/></div>
            </div>
            <div className="col-2">
              <h3 className="listening">Reporting &amp; Analytics</h3>
              <p className="listening">Track sales / channel performance and provide complete visibility to all stakeholders through interactive dashboards and custom reports.</p>
            </div>
          </div>
          <div className="container">
            <div className="col-2">
              <div className="image listening"><img src={productIA6}/></div>
            </div>
            <div className="col-2">
              <h3 className="listening">Invoicing &amp; Taxation</h3>
              <p className="listening">Enable e-invoicing for off-roll workers and contractors, and ensure 100% tax compliance through automated calculations / deductions for GST, VAT, TDS, etc</p>
            </div>
          </div>
          {/* <div className="container">
            <div className="col-2">
              <div className="image listening"><img src={productIA7}/></div>
            </div>
            <div className="col-2">
              <h3 className="listening">Plug & Play Ready</h3>
              <p className="listening">Hubspot, Salesforce, Microsoft Dynamics, Amazon Resdhift MySQL, Microsoft Excel, Quikbooks.</p>
            </div>
          </div> */
          }
        </section>
        <section className="section-plug">
          <div className="container">
            <div className="full-col text-center">
              <h2 className="listening">Plug &amp; Play Ready</h2>
              <p className="listening">Multiple data sources can be a real pain. We get it. Enter a world of seamless integration...almost instantly.</p>
              <div className="logos">
                <img className="listening" src={plugLogo1}/>
                <img className="listening delay-0" src={plugLogo2}/>
                <img className="listening delay-1" src={plugLogo3}/>
                <img className="listening" src={plugLogo4}/>
                <img className="listening delay-0" src={plugLogo5}/>
                <img className="listening delay-1" src={plugLogo6}/>
              </div>
            </div>
          </div>
        </section>
        <Testimonial/>
      </div>
    )
  }
}
