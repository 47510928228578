import React, {Component} from 'react';

import Testimonial from '../Testimonials';

import businessHeadsLandingImage from '../../assets/business-heads-landing.png';
import arrowUpLarge from '../../assets/arrow-up-large.svg';
import solutionsImage1 from '../../assets/business-heads-1.png';
import solutionsImage2 from '../../assets/business-heads-2.png';
import solutionsImage3 from '../../assets/business-heads-3.png';
import solutionsImage4 from '../../assets/business-heads-4.png';
import productIA1 from '../../assets/product-ia-1.svg';
import productIA2 from '../../assets/product-ia-2.svg';
import productIA3 from '../../assets/product-ia-3.svg';
import productIA4 from '../../assets/product-ia-4.svg';

export default class BusinessHeadsCXO extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isReady: false
    }
  }
  componentDidMount() {
    setTimeout(() => this.setState({isReady: 'ready'}), 100);
  }
  render() {
    return (
      <div className={'page-solution ' + this.state.isReady}>
        <section className="section-landing">
          <div className="container">
            <div className="col-2">
              <h1 className="offset">Ancora for Business Heads &amp; CXOs</h1>
              <p className="offset">Better and faster decision-making through real-time data access and actionable business insights</p>
            </div>
            <div className="col-2">
              <div className="spread offset">
                <div className="image">
                  <img src={businessHeadsLandingImage}/>
                </div>
              </div>
            </div>
          </div>
          <div className="flip-a"><img src={arrowUpLarge}/></div>
          <div className="flip-b"></div>
        </section>
        <section className="section-landing-mobile">
          <div className="container">
            <p className="listening">Better and faster decision-making through real-time data access and actionable business insights</p>
          </div>
        </section>
        <section className="section-laptop solution-mod">
          <div className="container">
            <div className="col-2">
              <h2 className="listening">Improved visibility for better decision making</h2>
              <p className="listening">Stay ahead of the competition and drive your business with data-driven decisions.</p>
            </div>
            <div className="col-2">
              <div className="points">
                <div>
                  <h3 className="listening">1.</h3>
                  <p className="listening">Do not wait till the end of the month to get that sales performance MIS. Use Ancora’s interactive dashboard to get real-time performance updates.</p>
                </div>
                <div>
                  <h3 className="listening delay-0">2.</h3>
                  <p className="listening delay-0">Make cluttered inboxes a thing of the past. Eliminate unnecessary email communication by streamlining all approvals and escalations workflows.</p>
                </div>
                <div>
                  <h3 className="listening">3.</h3>
                  <p className="listening">Improve team morale and engagement with accurate, on-time and transparent commissions and faster dispute resolution.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-solution">
          <div className="container">
            <div className="full-col text-center">
              <h2 className="listening">Solutions:</h2>
              <p className="listening">for Business Heads &amp; CXO Team</p>
            </div>
          </div>
          <div className="container">
            <div className="col-3">
              <img className="head listening" src={solutionsImage1}/>
            </div>
            <div className="col-3">
              <div className="content">
                <h2 className="listening text-gradient">1.</h2>
                <div>
                  <h5 className="listening">Channel Performance</h5>
                  <p className="listening">Track performance of individuals and teams across products and regions through real-time updates</p>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="image listening">
                <img src={productIA1}/>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="col-3">
              <img className="head listening" src={solutionsImage2}/>
            </div>
            <div className="col-3">
              <div className="content">
                <h2 className="listening text-gradient">2.</h2>
                <div>
                  <h5 className="listening">Team Engagement & Compliance</h5>
                  <p className="listening">Milestone tracking and leaderboards to improve workforce engagement and productivity</p>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="image listening">
                <img src={productIA2}/>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="col-3">
              <img className="head listening" src={solutionsImage3}/>
            </div>
            <div className="col-3">
              <div className="content">
                <h2 className="listening text-gradient">3.</h2>
                <div>
                  <h5 className="listening">Reporting & Analytics</h5>
                  <p className="listening">ICreate, view and download custom reports catering to all your MIS and decision-making requirements</p>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="image listening">
                <img src={productIA3}/>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="col-3">
              <img className="head listening" src={solutionsImage4}/>
            </div>
            <div className="col-3">
              <div className="content">
                <h2 className="listening text-gradient">4.</h2>
                <div>
                  <h5 className="listening">Approvals & Deviations</h5>
                  <p className="listening">Seamlessly track and resolve all channel / commission related issues, deviations and approval requests</p>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="image listening">
                <img src={productIA4}/>
              </div>
            </div>
          </div>
        </section>
        <Testimonial/>
      </div>
    )
  }
}
