import React, {Component} from 'react';

import Testimonial from '../Testimonials';

import salesCustomerLandingImage from '../../assets/sales-customer-landing.png';
import arrowUpLarge from '../../assets/arrow-up-large.svg';
import solutionsImage1 from '../../assets/sales-customer-1.png';
import solutionsImage2 from '../../assets/sales-customer-2.png';
import solutionsImage3 from '../../assets/sales-customer-3.png';
import productIA1 from '../../assets/product-ia-1.svg';
import productIA2 from '../../assets/product-ia-2.svg';
import productIA3 from '../../assets/product-ia-3.svg';

export default class SalesCustomerService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isReady: false
    }
  }
  componentDidMount() {
    setTimeout(() => this.setState({isReady: 'ready'}), 100);
  }
  render() {
    return (
      <div className={'page-solution ' + this.state.isReady}>
        <section className="section-landing">
          <div className="container">
            <div className="col-2">
              <h1 className="offset">Transparent & Automated Commissions</h1>
              <h5 className="offset">for your Sales & Customer Service Teams</h5>
              <p className="offset">Give your sales team a 24x7 visibility of their commissions, so that they spend more time on closing deals.</p>
            </div>
            <div className="col-2">
              <div className="spread offset">
                <div className="image">
                  <img src={salesCustomerLandingImage}/>
                </div>
              </div>
            </div>
          </div>
          <div className="flip-a"><img src={arrowUpLarge}/></div>
          <div className="flip-b"></div>
        </section>
        <section className="section-landing-mobile">
          <div className="container">
            <p className="listening">Give your sales team a 24x7 visibility of their commissions, so that they spend more time on closing deals.</p>
          </div>
        </section>
        <section className="section-laptop solution-mod">
          <div className="container">
            <div className="col-2">
              <h2 className="listening">How much commission have I earned?</h2>
              <p className="listening">Help your sales team answer the most important questions about their performance and earnings.</p>
            </div>
            <div className="col-2">
              <div className="points">
                <div>
                  <h3 className="listening">1.</h3>
                  <p className="listening">
                    <b>Am I on track to meeting my targets? How am I performing compared to my team?</b>
                    Sales teams perform better when they accurately know what they need to achieve. Help your teams keep track of their targets and performance in real-time</p>
                </div>
                <div>
                  <h3 className="listening delay-0">2.</h3>
                  <p className="listening delay-0">
                    <b>What will my next month’s paycheck look like? How can I improve my performance?</b>
                    Give your team a 24x7 visibility on their commissions, so that they can spend more time out there selling your products.</p>
                </div>
                <div>
                  <h3 className="listening">3.</h3>
                  <p className="listening">
                    <b>How did the management calculate my commission? Why is it different from what I expected?</b>
                    Create a more motivated and engaged workforce through transparent, on-time and accurate commissions.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-solution">
          <div className="container">
            <div className="full-col text-center">
              <h2 className="listening">Solutions:</h2>
              <p className="listening">for Sales Teams</p>
            </div>
          </div>
          <div className="container">
            <div className="col-3">
              <img className="head listening" src={solutionsImage1}/>
            </div>
            <div className="col-3">
              <div className="content">
                <h2 className="listening text-gradient">1.</h2>
                <div>
                  <h5 className="listening">Real-time Earnings Visibility</h5>
                  <p className="listening">Smart tracking to help sale teams stay on top of their targets and get real-time visibility on earnings</p>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="image listening">
                <img src={productIA1}/>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="col-3">
              <img className="head listening" src={solutionsImage2}/>
            </div>
            <div className="col-3">
              <div className="content">
                <h2 className="listening text-gradient">2.</h2>
                <div>
                  <h5 className="listening">Performance & KPI Tracking</h5>
                  <p className="listening">Automated incentive calculation for error-free processing and instant payouts</p>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="image listening">
                <img src={productIA2}/>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="col-3">
              <img className="head listening" src={solutionsImage3}/>
            </div>
            <div className="col-3">
              <div className="content">
                <h2 className="listening text-gradient">3.</h2>
                <div>
                  <h5 className="listening">Grievance & Dispute Resolution</h5>
                  <p className="listening">Integrated reporting and visualisation for real-time performance tracking & informed decision making</p>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="image listening">
                <img src={productIA3}/>
              </div>
            </div>
          </div>
        </section>
        <Testimonial/>
      </div>
    )
  }
}
