import React, {Component} from 'react';

import Testimonial from '../Testimonials';

import revenueOperationsLandingImage from '../../assets/revenue-operations-landing.png';
import arrowUpLarge from '../../assets/arrow-up-large.svg';
import solutionsImage1 from '../../assets/revenue-operations-1.png';
import solutionsImage2 from '../../assets/revenue-operations-2.png';
import solutionsImage3 from '../../assets/revenue-operations-3.png';
import solutionsImage4 from '../../assets/revenue-operations-4.png';
import solutionsImage5 from '../../assets/revenue-operations-5.png';
import productIA1 from '../../assets/product-ia-1.svg';
import productIA2 from '../../assets/product-ia-2.svg';
import productIA3 from '../../assets/product-ia-3.svg';
import productIA4 from '../../assets/product-ia-4.svg';
import productIA5 from '../../assets/product-ia-5.svg';

export default class RevenueOperations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isReady: false
    }
  }
  componentDidMount() {
    setTimeout(() => this.setState({isReady: 'ready'}), 100);
  }
  render() {
    return (
      <div className={'page-solution ' + this.state.isReady}>
        <section className="section-landing">
          <div className="container">
            <div className="col-2">
              <h1 className="offset">Automated commission calculations</h1>
              <h5 className="offset">for faster and accurate payouts</h5>
              <p className="offset">Ancora enables better time and manpower utilisation through elimination of manual data aggregation and cumbersome calculations.</p>
            </div>
            <div className="col-2">
              <div className="spread offset">
                <div className="image">
                  <img src={revenueOperationsLandingImage}/>
                </div>
              </div>
            </div>
          </div>
          <div className="flip-a"><img src={arrowUpLarge}/></div>
          <div className="flip-b"></div>
        </section>
        <section className="section-landing-mobile">
          <div className="container">
            <p className="listening">Ancora enables better time and manpower utilisation through elimination of manual data aggregation and cumbersome calculations.</p>
          </div>
        </section>
        <section className="section-laptop solution-mod">
          <div className="container">
            <div className="col-2">
              <h2 className="listening">Empower your Revenue & Operations teams</h2>
              <p className="listening">Save precious time & resources by allowing your RevOps teams to focus on strategic planning & growth.
              </p>
            </div>
            <div className="col-2">
              <div className="points">
                <div>
                  <h3 className="listening">1.</h3>
                  <p className="listening">Eliminate manual aggregation and structuring of transactional data through seamless real-time data pulls</p>
                </div>
                <div>
                  <h3 className="listening delay-0">2.</h3>
                  <p className="listening delay-0">Say goodbye to complicated, error-prone excel models for calculating commissions and incentives.</p>
                </div>
                <div>
                  <h3 className="listening">3.</h3>
                  <p className="listening">Don’t wait till the end of the month to get visibility on the team achievements and commissions - strategize better with real-time & commissions visibility.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-solution">
          <div className="container">
            <div className="full-col text-center">
              <h2 className="listening">Solutions:</h2>
              <p className="listening">for RevOps Team</p>
            </div>
          </div>
          <div className="container">
            <div className="col-3">
              <img className="head listening" src={solutionsImage1}/>
            </div>
            <div className="col-3">
              <div className="content">
                <h2 className="listening text-gradient">1.</h2>
                <div>
                  <h5 className="listening">Data Integration</h5>
                  <p className="listening">Complete flexibility, ranging from excel data upload to API-driven automated data pull and aggregation from multiple systems.</p>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="image listening">
                <img src={productIA1}/>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="col-3">
              <img className="head listening" src={solutionsImage2}/>
            </div>
            <div className="col-3">
              <div className="content">
                <h2 className="listening text-gradient">2.</h2>
                <div>
                  <h5 className="listening">Commission Rule Engine</h5>
                  <p className="listening">Automate highly complex commission plans and run custom kicker programs to achieve growth, without worrying about manual calculations ever.</p>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="image listening">
                <img src={productIA2}/>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="col-3">
              <img className="head listening" src={solutionsImage3}/>
            </div>
            <div className="col-3">
              <div className="content">
                <h2 className="listening text-gradient">3.</h2>
                <div>
                  <h5 className="listening">Simulation Engine</h5>
                  <p className="listening">Create accurate budgets and forecasts through our simulation engine. Test multiple scenarios to eliminate unpleasant surprises.</p>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="image listening">
                <img src={productIA3}/>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="col-3">
              <img className="head listening" src={solutionsImage4}/>
            </div>
            <div className="col-3">
              <div className="content">
                <h2 className="listening text-gradient">4.</h2>
                <div>
                  <h5 className="listening">A/B Testing</h5>
                  <p className="listening">Optimise your cost structure and efficiently drive business growth by running parallel and challenger commission plans</p>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="image listening">
                <img src={productIA4}/>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="col-3">
              <img className="head listening" src={solutionsImage5}/>
            </div>
            <div className="col-3">
              <div className="content">
                <h2 className="listening text-gradient">5.</h2>
                <div>
                  <h5 className="listening">Digital Workflows</h5>
                  <p className="listening">Ensure zero delays due to unread-emails by creating multi-layered workflows for special approvals, escalations and dispute resolution</p>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="image listening">
                <img src={productIA5}/>
              </div>
            </div>
          </div>
        </section>
        <Testimonial/>
      </div>
    )
  }
}
