import React, {Component} from 'react';

import blueRound from '../assets/blue-round.svg';
import blueRoundL from '../assets/blue-round-l.svg';
import arrowUpLarge from '../assets/arrow-up-large.svg';

export default class Join extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (<React.Fragment>
      <section className="section-testimonial-round">
        <img src={blueRound}/>
        <img src={blueRoundL}/>
      </section>
      <section className="section-join gradient">
        <div className="container">
          <div className="full-col">
            <h2>Join Our Team!</h2>
            <p>Ancora is a young and dynamic company looking to change the world of sales through transparent &amp; seamless incentives, and real time engagement.</p>
            <p>We are a fast growing company and we could use some good talent like you...</p>
            <a href="https://www.linkedin.com/company/ancorafintech/jobs/" target="_blank">
              <span className="text-gradient">Join us</span>
            </a>
          </div>
        </div>
        {/* <img src={arrowUpLarge}/> */}
      </section>
    </React.Fragment>)
  }
}
