import React, {Component} from 'react';
import {Carousel} from 'react-responsive-carousel';

import blueRound from '../assets/blue-round.svg';
import blueRoundL from '../assets/blue-round-l.svg';
import leftQuot from '../assets/left-quot.svg';
import rightQuot from '../assets/right-quot.svg';
import testimonial1 from '../assets/testimonial-1.png';

export default class Testimonials extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render() {
    return <React.Fragment></React.Fragment>
{/*    return (
      <React.Fragment>
        <section className="section-testimonial-round">
          <img src={blueRound}/>
          <img src={blueRoundL}/>
        </section>
        <section className="section-testimonial gradient">
          <div className="container">
            <div className="full-col">
              <h2>Here’s what our customers have to say...</h2>
              <div className="box">
                <Carousel autoPlay={true} infiniteLoop={true} interval={3000} transitionTime={600} onChange={this.testimonialSlideChange} showArrows={false} showStatus={false} showIndicators={true} showThumbs={false} stopOnHover={false}>
                  <div className="testimonial">
                    <img src={testimonial1}/>
                    <div className="content">
                      <p className="head">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea co</p>
                      <h6>- Ajay Singh</h6>
                      <p className="sub">Vice President - Sales</p>
                    </div>
                  </div>
                  <div className="testimonial">
                    <img src={testimonial1}/>
                    <div className="content">
                      <p className="head">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea co</p>
                      <h6>- Ajay Singh</h6>
                      <p className="sub">Vice President - Sales</p>
                    </div>
                  </div>
                  <div className="testimonial">
                    <img src={testimonial1}/>
                    <div className="content">
                      <p className="head">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea co</p>
                      <h6>- Ajay Singh</h6>
                      <p className="sub">Vice President - Sales</p>
                    </div>
                  </div>
                </Carousel>
              </div>
            </div>
          </div>
          <img className="quot left" src={leftQuot}/>
          <img className="quot right" src={rightQuot}/>
        </section>
      </React.Fragment>
    ) */}
  }
}
