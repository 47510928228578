import React, {Component} from 'react';

import Testimonial from '../Testimonials';

import performanceManagementLandingImage from '../../assets/performance-management-landing.jpg';
import arrowUpLarge from '../../assets/arrow-up-large.svg';
import productLogo1 from '../../assets/product-logo-1.png';
import productLogo2 from '../../assets/product-logo-2.png';
import productLogo3 from '../../assets/product-logo-3.png';
import productLogo4 from '../../assets/product-logo-4.png';
import laptop from '../../assets/laptop-performance.png';
import productIA1 from '../../assets/product-ia-1.svg';
import productIA2 from '../../assets/product-ia-2.svg';
import productIA3 from '../../assets/product-ia-3.svg';
import productIA4 from '../../assets/product-ia-4.svg';
import plugLogo1 from '../../assets/plug-logo-1.png';
import plugLogo2 from '../../assets/plug-logo-2.png';
import plugLogo3 from '../../assets/plug-logo-3.png';
import plugLogo4 from '../../assets/plug-logo-4.png';
import plugLogo5 from '../../assets/plug-logo-5.png';
import plugLogo6 from '../../assets/plug-logo-6.png';

export default class PerformanceManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isReady: false
    }
  }
  componentDidMount() {
    setTimeout(() => this.setState({isReady: 'ready'}), 100);
  }
  render() {
    return (
      <div className={'page-product ' + this.state.isReady}>
        <section className="section-landing">
          <div className="container">
            <div className="col-2">
              <h1 className="offset">Increase your workforce productivity</h1>
              <h5 className="offset">with Gamified Incentives & Performance Management.</h5>
              <p className="offset">A workforce engagement platform that drives productivity & fosters healthy competition through interactive performance management & incentive gamification.</p>
            </div>
            <div className="col-2">
              <div className="spread offset">
                <div className="image">
                  <img src={performanceManagementLandingImage}/>
                </div>
              </div>
            </div>
          </div>
          <div className="flip-a"><img src={arrowUpLarge}/></div>
          <div className="flip-b"></div>
        </section>
        <section className="section-landing-mobile">
          <div className="container">
            <p className="listening">A workforce engagement platform that drives productivity & fosters healthy competition through interactive performance management & incentive gamification.</p>
          </div>
        </section>
        <section className="section-laptop">
          <div className="container">
            <div className="col-2">
              <h2 className="listening">Complete Performance Management</h2>
              <p className="listening">A one-stop performance management platform that engages, empowers & motivates employees through data-driven tools & actionable insights
              </p>
              <div className="points">
                <div>
                  <h3 className="listening">1.</h3>
                  <p className="listening">Digital capture and enrich personal, financial and transactional data</p>
                </div>
                <div>
                  <h3 className="listening delay-0">2.</h3>
                  <p className="listening delay-0">Instant data verification through public / private databases and APIs</p>
                </div>
                <div>
                  <h3 className="listening">3.</h3>
                  <p className="listening">Business Rule Engine & digital workflow data-led decisioning & approvals</p>
                </div>
                <div>
                  <h3 className="listening delay-0">4.</h3>
                  <p className="listening delay-0">Legally-compliant digital contract generation, eSign and vendor code creation</p>
                </div>
              </div>
              {/* <p className="listening">Companies we helped...</p>
              <div className="logos">
                <img className="listening" src={productLogo1}/>
                <img className="listening delay-0" src={productLogo2}/>
                <img className="listening delay-1" src={productLogo3}/>
                <img className="listening delay-2" src={productLogo4}/>
              </div> */
              }
            </div>
            <div className="col-2 laptop">
              <div className="spread listening"></div>
              <img className="listening" src={laptop}/>
            </div>
          </div>
        </section>
        <section className="section-product">
          <div className="container">
            <div className="col-2">
              <div className="image listening"><img src={productIA1}/></div>
            </div>
            <div className="col-2">
              <h3 className="listening">Milestone Tracking & Earning Visibility</h3>
              <p className="listening">Define and track milestones based on individual / team performance, and provide real-time earnings visibility</p>
            </div>
          </div>
          <div className="container">
            <div className="col-2">
              <div className="image listening"><img src={productIA2}/></div>
            </div>
            <div className="col-2">
              <h3 className="listening">Smart Nudges & Reminders</h3>
              <p className="listening">Give you team that extra push by letting them know when they are close to their targets & milestones</p>
            </div>
          </div>
          <div className="container">
            <div className="col-2">
              <div className="image listening"><img src={productIA3}/></div>
            </div>
            <div className="col-2">
              <h3 className="listening">Leaderboards & Notifications</h3>
              <p className="listening">Foster healthy competition amongst teams and give due recognition the top-performers</p>
            </div>
          </div>
          <div className="container">
            <div className="col-2">
              <div className="image listening"><img src={productIA4}/></div>
            </div>
            <div className="col-2">
              <h3 className="listening">SDK & Microservices</h3>
              <p className="listening">Integrate individual tools & modules with existing organisational apps for a seamless user experience</p>
            </div>
          </div>
        </section>
        <section className="section-plug">
          <div className="container">
            <div className="full-col text-center">
              <h2 className="listening">Plug & Play Ready</h2>
              <p className="listening">Multiple data sources can be a real pain. We get it. Enter a world of seamless integration...almost instantly.</p>
              <div className="logos">
                <img className="listening" src={plugLogo1}/>
                <img className="listening delay-0" src={plugLogo2}/>
                <img className="listening delay-1" src={plugLogo3}/>
                <img className="listening" src={plugLogo4}/>
                <img className="listening delay-0" src={plugLogo5}/>
                <img className="listening delay-1" src={plugLogo6}/>
              </div>
            </div>
          </div>
        </section>
        <Testimonial/>
      </div>
    )
  }
}
