import React, {Component} from 'react';

import Join from './Join';

import valueIcon1 from '../assets/values-01.svg';
import valueIcon2 from '../assets/values-02.svg';
import valueIcon3 from '../assets/values-03.svg';
import valueIcon4 from '../assets/values-04.svg';
// import valueIcon5 from '../assets/values-05.svg';
// import valueIcon6 from '../assets/values-06.svg';
import team1 from '../assets/team-01.jpg';
import team2 from '../assets/team-02.jpg';
import team3 from '../assets/team-03.jpg';
import teamLn from '../assets/team-ln.svg';

export default class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isReady: false
    }
  }
  componentDidMount() {
    setTimeout(() => this.setState({isReady: 'ready'}), 100);
  }
  render() {
    return (
      <div className={'page-about ' + this.state.isReady}>
        <section className="about-landing">
          <div className="container">
            <div className="full-col text-center head">
              <h1 className="offset">We’re on a mission to make incentives and sales more efficient.</h1>
              <p className="offset">And we believe our values, people and work ethic helps us do this every single day.</p>
            </div>
            <div className="square"></div>
            <div className="square"></div>
            <div className="square"></div>
            <div className="square"></div>
          </div>
          <div className="container">
            <div className="col-2 exactly">
              <h5 className="listening">What do we do exactly.</h5>
              <p className="listening">Ancora’s helps large and hyper-growth companies drive their sales performance through automated and gamified commissions. We, at Ancora, are creating an incentives & payouts eco-system that enables businesses to drive growth at a reasonable cost, while empowering workers through transparent &amp; fair compensation.</p>
            </div>
            <div className="col-2">
              <div className="stats">
                <div className="stat">
                  <p className="listening">Founded in</p>
                  <h3 className="text-gradient listening">2019</h3>
                </div>
                <div className="stat">
                  <p className="listening">Headquarters</p>
                  <h3 className="text-gradient listening">Gurugram</h3>
                </div>
                <div className="stat">
                  <p className="listening">Processed Incentives &amp;<br/>
                    Commissions</p>
                  <h3 className="text-gradient listening">$80 Million</h3>
                </div>
                <div className="stat">
                  <p className="listening">Savings in<br/>Admin Costs</p>
                  <h3 className="text-gradient listening">$3 Million</h3>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="about-values">
          <div className="container">
            <div className="full-col text-center">
              <h2 className="listening">Our Core Values</h2>
              <p className="listening">We believe it’s our strong belief in our core values that makes us....us.</p>
            </div>
          </div>
          <div className="container">
            <div className="col-3 listening">
              <div className="value">
                <img src={valueIcon1}/>
                <div className="content">
                  <h6 className="listening text-gradient">Transparency</h6>
                  <p className="listening">Clear all the way. Always.</p>
                </div>
              </div>
            </div>
            <div className="col-3 listening delay-0">
              <div className="value">
                <img src={valueIcon2}/>
                <div className="content">
                  <h6 className="listening text-gradient delay-0">Precision</h6>
                  {/* <p className="listening delay-0">CIt’s data and money. No errors.</p> */}
                  <p className="listening delay-0">100% accurate. No errors.</p>
                </div>
              </div>
            </div>
            <div className="col-3 listening delay-1">
              <div className="value">
                <img src={valueIcon3}/>
                <div className="content">
                  <h6 className="listening text-gradient delay-1">Speed</h6>
                  <p className="listening delay-1">It’s the only way we know how to.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="col-3 listening">
              <div className="value">
                <img src={valueIcon4}/>
                <div className="content">
                  <h6 className="listening text-gradient">Communicative</h6>
                  <p className="listening">No radio silence.</p>
                </div>
              </div>
            </div>
            <div className="col-3 listening delay-0">
              <div className="value">
                <img src={valueIcon4}/>
                <div className="content">
                  <h6 className="listening text-gradient delay-0">Engaging</h6>
                  <p className="listening delay-0">Reflects our business after all.</p>
                </div>
              </div>
            </div>
            <div className="col-3 listening delay-1">
              <div className="value">
                <img src={valueIcon4}/>
                <div className="content">
                  <h6 className="listening text-gradient delay-1">Convenience</h6>
                  <p className="listening delay-1">No unnecessary complications.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="about-leadership">
          <div className="container">
            <div className="col-40p">
              <h2 className="listening">Our Leadership</h2>
              <p className="head listening">Meet the team behind the standout sales performance management solution for companies that seek to increase accuracy and transparency.</p>
            </div>
            <div className="col-60p">
              <div className="member"><img className="listening" src={team1}/>
                <h5 className="listening">Rohit Panwar</h5>
                <p className="listening">Business Operations &amp; Strategy</p>
                <a href="https://www.linkedin.com/in/rohit-panwar-1506" target="_blank"><img src={teamLn}/></a>
              </div>
              <div className="member"><img className="listening delay-0" src={team2}/>
                <h5 className="listening delay-0">Rahul Singh</h5>
                <p className="listening delay-0">Sales &amp; Customer Success</p>
                <a href="https://www.linkedin.com/in/meetrahulsingh" target="_blank"><img src={teamLn}/></a>
              </div>
              <div className="member"><img className="listening delay-1" src={team3}/>
                <h5 className="listening delay-1">Aman Garg</h5>
                <p className="listening delay-1">Product &amp; Technology</p>
                <a href="https://www.linkedin.com/in/aman1032" target="_blank"><img src={teamLn}/></a>
              </div>
            </div>
          </div>
        </section>
        <Join/>
      </div>
    );
  }
}
