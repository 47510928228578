import React, {Component} from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {Carousel} from 'react-responsive-carousel';
import ReactDOM from 'react-dom';

import Testimonials from '../components/Testimonials';

import landingImage from '../assets/landing-image.jpg';
import screen1 from '../assets/screen-1.png';
import screen2 from '../assets/screen-2.png';
import screen3 from '../assets/screen-3.png';
import greyBullet from '../assets/grey-bullet.svg';
import arrowUpLarge from '../assets/arrow-up-large.svg';
import leftArrowSolutions from '../assets/left-arrow-solutions.svg';
import solutionsImage1 from '../assets/solutions-1.png';
import solutionsImage2 from '../assets/solutions-2.png';
import solutionsImage3 from '../assets/solutions-3.png';
import solutionsImage4 from '../assets/solutions-4.png';
import blueArrowRight from '../assets/blue-arrow-right.svg';
import logo1 from '../assets/Microsoft-logo.png';
import logo2 from '../assets/Dell_Logo.png';
import logo3 from '../assets/Schneider_Electric_2007.png';
import logo4 from '../assets/Logo-acko.png';
import caseImage1 from '../assets/case-1.png';
import caseImage2 from '../assets/case-2.png';
import caseImage3 from '../assets/case-3.png';
import caseImage4 from '../assets/case-4.png';
import caseImage5 from '../assets/case-5.png';
import caseLogo1 from '../assets/case-logo-1.svg';
import caseLogo2 from '../assets/case-logo-2.svg';
import caseLogo3 from '../assets/case-logo-3.svg';
import caseLogo4 from '../assets/case-logo-4.svg';
import caseLogo5 from '../assets/case-logo-5.svg';
import caseLogo6 from '../assets/case-logo-6.svg';
import videoSample from '../assets/video-sample.jpg';
import play from '../assets/play.svg';
import blueRound from '../assets/blue-round.svg';
import blueRoundL from '../assets/blue-round-l.svg';
import leftQuot from '../assets/left-quot.svg';
import rightQuot from '../assets/right-quot.svg';
import testimonial1 from '../assets/testimonial-1.png';
import commission from '../assets/software-1.png';
import onboarding from '../assets/software-2.png';
import performanceManagement from '../assets/software-3.png';
import pnb_testimonial from '../assets/PNB-logo.jpg';
import icon1 from '../assets/Icon1.svg';
import icon2 from '../assets/Icon2.svg';
import icon3 from '../assets/Icon3.svg';
import icon4 from '../assets/Icon4.svg';
import icon5 from '../assets/Icon5.svg';
import icon6 from '../assets/Icon6.svg';
import icon7 from '../assets/Icon7.svg';
import icon8 from '../assets/Icon8.svg';

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isReady: '',
      screenSlideIndex: 0
    };
    this.landingSlideChange = this.landingSlideChange.bind(this);
  }
  componentDidMount() {
    setTimeout(() => this.setState({isReady: 'ready'}), 100);
  }
  render() {
    return (
      <div className={('page-home ' + this.state.isReady)}>

{/*Section 1(Banner Starts)*/}
      <section className="section-landing">
      <div className="container">
        <div className="col-2">
          <h1 className="offset">Grow your business faster.</h1>
          <h5 className="offset">With Incentive &amp; Commission Automation.</h5>
          <p className="offset">We believe that a company’s biggest asset is their workforce, so we built an incentive platform that helps you engage and reward your customer-facing teams, without losing sight of your business goals.</p>
        </div>
        <div className="col-2">
          <div className="spread offset">
            <div className="image">
              <img src={landingImage}/>
            </div>
          </div>
        </div>
      </div>
      <div className="flip-a"><img src={arrowUpLarge}/></div>
      <div className="flip-b"></div>
      </section>
      {/* <section className="section-landing-mobile">
      <div className="container">
        <p>We believe that a company’s biggest asset is their workforce, so we built an incentive platform that helps you engage and reward your customer-facing teams, without losing sight of your business goals.</p>
      </div>
      </section> */}
        {/* <section className="section-landing">
          <div className="container">
            <div className="col-2">
              <h1 className="offset">Automated Commissions for more engaged &amp; driven teams.</h1>
          {  /*  <h5 className="offset">With Incentive &amp; Commission Automation.</h5> *
              <p className="offset">With Ancora's no-code Commission Automation platform, you can streamline commission calculation & payouts while boosting your teams' performance through gamified incentives & AI-driven nudges.</p>
              <div className="demo-box">
              {/* <Link to="/book-a-demo" className="demo">Book a demo</Link> *
                <a href="http://calendly.com/ancorafintech" target="_blank" className="demo"><button className="btn book">Book a demo</button></a>
              </div>
            </div>
            <div className="col-2">
              <div className="spread offset">
                <div className="image">
                  <img src={screen1}/>
                </div>
              </div>
            </div>
          </div>
          <div className="flip-a"><img src={arrowUpLarge}/></div>
          <div className="flip-b"></div>
        </section> */}

{/*Section 1 ends*/}

    {/* <section className="section-landing-mobile">
          <div className="container">
            <p>We believe that a company’s biggest asset is their workforce, so we built an incentive platform that helps you engage and reward your customer-facing teams, without losing sight of your business goals.</p>
          </div>
        </section>



       <section className="section-screen">
          <div className="container">
            <div className="col-60p listening">
              <Carousel autoPlay={true} infiniteLoop={true} interval={3000} transitionTime={600} onChange={this.landingSlideChange} showArrows={false} showStatus={false} showIndicators={true} showThumbs={false} stopOnHover={false} animationHandler={'fade'}>
                <div className="img-container"><img src={screen1} className="screen-img"/></div>
                <div className="img-container"><img src={screen1} className="screen-img"/></div>
                <div className="img-container"><img src={screen1} className="screen-img"/></div>
                {/* <div className="img-container"><img src={screen2} className="screen-img"/></div>
                <div className="img-container"><img src={screen3} className="screen-img"/></div> */
              }
              {/* </Carousel>
            </div>
            <div className="col-40p content">
              <p className="listening">Calculate sales incentives &amp; commissions on-the-go</p>
              <div className="listening">
                <h4 className={(
                    this.state.screenSlideIndex === 0
                      ? 'active'
                      : ''
                  )}><img src={greyBullet}/>Create Roles &amp; Hierarchies</h4>
                <h4 className={(
                    this.state.screenSlideIndex === 1
                      ? 'active'
                      : ''
                  )}><img src={greyBullet}/>Create &amp; Edit Commission Plans</h4>
                <h4 className={(
                    this.state.screenSlideIndex === 2
                      ? 'active'
                      : ''
                  )}><img src={greyBullet}/>Real-time Earnings Visibility</h4>
              </div>
              <p className="listening">Simply schedule a demo with our team, help us understand your business and watch how Ancora adapts to your business need in a matter of seconds.</p>
            </div>
          </div>
          <img className="arrow listening" src={leftArrowSolutions}/>
        </section>
        <section className="section-screen-mobile">
          <div className="container">
            <div className="full-col">
              <p className="listening">Calculate sales incentives &amp; commissions on-the-go:</p>
              <div className="image listening">
                <img src={screen1}/>
              </div>
              <h4 className="listening">Create Roles &amp; Hierarchies</h4>
              <h4 className="listening">Create &amp; Edit Commission Plans</h4>
              {/* <div className="image listening">
                <img src={screen2}/>
              </div> */}
            {/*  <h4 className="listening">Real-time Earnings Visibility</h4>
              {/* <div className="image listening">
                <img src={screen3}/>
              </div> */}
        {/*    </div>
          </div>
        </section> */}

{/*Section 2(Clients starts)*/}

        {/* <section className="section-achievements">
          <div className="container">
            <div className="full-col text-center">
              <p className="text-gradient listening">- Trusted by the best -</p>
              <div className="col-sm-3 point listening">
              {/*  <h2 className="text-gradient">
                  <span>₹</span>35<span>Crs+</span>
                </h2> *
                <img src={logo1}/>
              </div>
              <div className="col-sm-3 point listening delay-0">
              {/*  <h2 className="text-gradient">60k+</h2> *
               <img src={logo2}/>
              </div>
              <div className="col-sm-3 point listening delay-1">
              {/*  <h2 className="text-gradient">
                  <span>₹</span>15<span>Crs+</span>
                </h2> *
                <img src={logo3}/>
              </div>
              <div className="col-sm-3 point listening delay-2">
              {/*  <h2 className="text-gradient">20k+</h2> *
                <img src={logo4}/>
              </div>
            </div>
          </div>
        </section> */}

{/*Section 2 ends

Section 3(chievements starts)*/}

        <section className="section-achievements">
          <div className="container">
            <div className="full-col text-center">
              <h1 className="text-gradient listening">Accelarate your growth with Ancora</h1>
              <div className="point listening">
                <h2 className="text-gradient">
                  <span>₹</span>35<span>Crs+</span>
                </h2>
                <span>Commissions Processed Every Month</span>
              </div>
              <div className="point listening delay-0">
                <h2 className="text-gradient">60k+</h2>
                <span>Users Engaged</span>
              </div>
              <div className="point listening delay-1">
                <h2 className="text-gradient">
                  <span>₹</span>15<span>Crs+</span>
                </h2>
                <span> Annual Cost Savings for Clients</span>
              </div>
              <div className="point listening delay-2">
                <h2 className="text-gradient">20k+</h2>
                <span>Admin hours saved</span>
              </div>
            </div>
          </div>
        </section>

{/*Section 3 ends*/}


{/*Section 5(What can our software do for you! starts)*/}
      <section className="section-software">
        <div className="container">
          <div className="full-col title text-center">
              <p className="text-gradient title listening">Ancora for Enterprises</p>
          </div>
        </div>
        <div className="container">
          <div className="col-2">
            <h1 className="offset">Commission Automation</h1>
        {  /*  <h5 className="offset">With Incentive &amp; Commission Automation.</h5> */}
            <p className="offset">With our no-code Commission Engine, you can design flows, automate payouts, and seamlessly collaborate across departments &amp; functions.</p>
            <ul>
            <li className="offset">100% accurate and on-time commissions</li>
            <li className="offset">Real-time earnings visibility</li>
            <li className="offset">Customized &amp; flexible digital workflow</li>
            <li className="offset">Automated commissions for cost &amp; time-saving</li>
            <li className="offset">Zero errors, with no risk of overpayments and frauds</li>
            <li className="offset">Easy Integration with all CRM, ERP &amp; Finance Systems</li>
            <li className="offset">Access for on-field sales reps &amp; teams</li>
            <li className="offset">Audit trails &amp; 100% tax compliance</li>
            </ul>
            <a href="/products/incentive-automation" target="_blank">Learn more</a>
          </div>
          <div className="col-2">
            <div className="spread offset">
              <div className="image">
                <img src={commission}/>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-software desktop-view">
        <div className="container">
          <div className="col-2">
          <div className="spread offset">
            <div className="image">
              <img src={onboarding}/>
            </div>
          </div>
          </div>
          <div className="col-2">
          <h1 className="offset">Acquisition &amp; Onboarding</h1>
      {  /*  <h5 className="offset">With Incentive &amp; Commission Automation.</h5> */}
          <p className="offset">Our paperless channel onboarding platform helps you boost your market reach while reducing vendor onboarding costs.</p>
          <ul>
          <li className="offset">Digital data capture</li>
          <li className="offset">Instant, API-based personal and financial verification</li>
          <li className="offset">Automated, rule-based approval or rejection of applications</li>
          <li className="offset">Customised digital agreement &amp; eSigning</li>
          <li className="offset">Channel onboarding within 10 minutes</li>
          <li className="offset">100% audit &amp; compliance proof</li>
          <li className="offset">Seamless integration with existing systems</li>
          </ul>
          <a href="/products/acquisition-onboarding" target="_blank">Learn more</a>
          </div>
        </div>
      </section>

      <section className="section-software mobile-view">
        <div className="container">
          <div className="col-2">
          <h1 className="offset">Acquisition &amp; Onboarding</h1>
      {  /*  <h5 className="offset">With Incentive &amp; Commission Automation.</h5> */}
          <p className="offset">Our paperless channel onboarding platform helps you boost your market reach while reducing vendor onboarding costs.</p>
          <ul>
          <li className="offset">Digital data capture</li>
          <li className="offset">Instant, API-based personal and financial verification</li>
          <li className="offset">Automated, rule-based approval or rejection of applications</li>
          <li className="offset">Customised digital agreement &amp; eSigning</li>
          <li className="offset">Channel onboarding within 10 minutes</li>
          <li className="offset">100% audit &amp; compliance proof</li>
          <li className="offset">Seamless integration with existing systems</li>
          </ul>
          <a href="/products/acquisition-onboarding" target="_blank">Learn more</a>
        </div>
          <div className="col-2">
          <div className="spread offset">
            <div className="image">
              <img src={onboarding}/>
            </div>
          </div>
        </div>
      </div>
      </section>

      <section className="section-software">
        <div className="container">
          <div className="col-2">
            <h1 className="offset">Performance Management</h1>
        {  /*  <h5 className="offset">With Incentive &amp; Commission Automation.</h5> */}
            <p className="offset">Our AI engine helps improve team performance and boost the workforce happiness index through data and trend-driven recommendations &amp; insights.</p>
            <ul>
            <li className="offset">Increased ROI through improved tracking of existing channels</li>
            <li className="offset">Custom reports for proactive &amp; strategic decision making</li>
            <li className="offset">Real-time performance tracking and trend analysis</li>
            <li className="offset">Actionable insights to individuals to meet targets</li>
            <li className="offset">Realtime earnings &amp; performance visibility</li>
            <li className="offset">Gamified incentives &amp; collaboration with contests, leaderboards &amp; smart notifications</li>
            </ul>
            <a href="/products/performance-management" target="_blank">Learn more</a>
          </div>
          <div className="col-2">
            <div className="spread offset">
              <div className="image">
                <img src={performanceManagement}/>
              </div>
            </div>
          </div>
        </div>
      </section>

{/*Section 5 ends*/}

{/*Section 4(Why Ancora starts)*/}

<section className="section-cases">
        <div className="container">
          <div className="full-col title text-center">
              <p className="text-gradient title listening">Why Ancora?</p>
          </div>
        </div>
        <div className="container">
          <div className="col-3">
            <div className="content listening">
              {/*<img src={caseImage1} className="head"/>*/}
              <h2>FLEXIBLE</h2>
              <img src={caseLogo1} className="logo"/>
              <p>No-Code Commission Engine with 10,000+ variables to design and automate complex commission plans.</p>
            </div>
          </div>
          <div className="col-3">
            <div className="content listening delay-0">
              {/*<img src={caseImage2} className="head"/>*/}
              <h2>SCALABLE</h2>
              <img src={caseLogo2} className="logo"/>
              <p>Our engine can seamlessly manage &amp; engage 100,000+ concurrent users.</p>
            </div>
          </div>
          <div className="col-3">
            <div className="content listening delay-1">
              {/*{/*<img src={caseImage3} className="head"/>*/}
              <h2>INTELLIGENT</h2>
              <img src={caseLogo3} className="logo"/>
              <p>Boost performance through AI-driven, actionable insights.</p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="col-3">
            <div className="content listening">
              {/*<img src={caseImage4} className="head"/>*/}
              <h2>SEAMLESS</h2>
              <img src={caseLogo5} className="logo"/>
              <p>Seamless integration with all your systems, including all leading CRMs, ERPs, HRMS, Accounting, as well as Banking Software.</p>
            </div>
          </div>
          <div className="col-3">
            <div className="content listening delay-0">
              {/*<img src={caseImage5} className="head"/>*/}
              <h2>ADAPTIVE</h2>
              <img src={caseLogo6} className="logo"/>
              {/* <p>Realtime earning visibility and payments tracking.</p> */}
              <p>Create custom reports and dashboards and track channel performance in real-time.</p>
            </div>
          </div>
          <div className="col-3">
            <div className="content listening delay-0">
              {/*<img src={caseImage5} className="head"/>*/}
              <h2>COLLABORATIVE</h2>
              <img src={caseLogo4} className="logo"/>
              <p>From Sales to Finance, Ancora solves everyone's challenges and drives collaboration</p>
            </div>
          </div>
        </div>
      </section>

{/*Section 4 ends*/}
{/*Section 6(Industries starts)*/}

      <section className="section-industries">
        <div className="container">
          <div className="full-col title text-center">
              <p className="text-gradient title listening">Ancora across Industries</p>
          </div>
        </div>
        <div className="container">
          <div className="col-2">
            <div className="content listening">
              {/*<img src={caseImage1} className="head"/>*/}
              <img src={icon1} className="logo"/>
              <h4>Banking &amp; Insurance</h4>
            </div>
          </div>
          <div className="col-2">
            <div className="content listening">
              {/*<img src={caseImage1} className="head"/>*/}
              <img src={icon2} className="logo"/>
              <h4>Real Estate</h4>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="col-2">
            <div className="content listening">
              {/*<img src={caseImage1} className="head"/>*/}
              <img src={icon3} className="logo"/>
              <h4>Wealth Management</h4>
            </div>
          </div>
          <div className="col-2">
            <div className="content listening">
              {/*<img src={caseImage1} className="head"/>*/}
              <img src={icon4} className="logo"/>
              <h4>Logistics &amp; Delivery</h4>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="col-2">
            <div className="content listening">
              {/*<img src={caseImage1} className="head"/>*/}
              <img src={icon5} className="logo"/>
              <h4>Pharmaceuticals</h4>
            </div>
          </div>
          <div className="col-2">
            <div className="content listening">
              {/*<img src={caseImage1} className="head"/>*/}
              <img src={icon6} className="logo"/>
              <h4>Consumer Goods</h4>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="col-2">
            <div className="content listening">
              {/*<img src={caseImage1} className="head"/>*/}
              <img src={icon7} className="logo"/>
              <h4>Digital Marketplaces </h4>
            </div>
          </div>
          <div className="col-2">
            <div className="content listening">
              {/*<img src={caseImage1} className="head"/>*/}
              <img src={icon8} className="logo"/>
              <h4>Telesales &amp; Shared Services  </h4>
            </div>
          </div>
        </div>
        </section>




{/*Section 6 ends

Section 7(Testimonials starts)*/}

        <section className="section-testimonial-round">
          <img src={blueRound}/>
          <img src={blueRoundL}/>
        </section>
        <section className="section-testimonial gradient">
          <div className="container">
            <div className="full-col">
              <h2>Here’s what our customers have to say...</h2>
              <div className="box">
                <Carousel autoPlay={true} infiniteLoop={true} interval={3000} transitionTime={600} onChange={this.testimonialSlideChange} showArrows={false} showStatus={false} showIndicators={true} showThumbs={false} stopOnHover={false}>
                  <div className="testimonial">
                  <div className="image">
                    <img src={pnb_testimonial}/>
                  </div>
                    <div className="content">
                      <p className="head">Ancora has helped streamline our commission plans with 100% accurate and on-time payouts. The platform has helped increase the throughput from our sales channels &amp; partners, while freeing-up precious manpower in our operations &amp; accounting functions.</p>
                      <h6>- Nitant Desai</h6>
                      <p className="sub">Chief Centralized Operations &amp; Technology Officer</p>
                      <p className="sub2">PNB Housing Finance Limited</p>
                    </div>
                  </div>
                  {/* <div className="testimonial">
                    <img src={testimonial1}/>
                    <div className="content">
                      <p className="head">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea co</p>
                      <h6>- Ajay Singh</h6>
                      <p className="sub">Vice President - Sales</p>
                    </div>
                  </div>
                  <div className="testimonial">
                    <img src={testimonial1}/>
                    <div className="content">
                      <p className="head">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea co</p>
                      <h6>- Ajay Singh</h6>
                      <p className="sub">Vice President - Sales</p>
                    </div>
                  </div> */}
                </Carousel>
              </div>
            </div>
          </div>
          <img className="quot left" src={leftQuot}/>
          <img className="quot right" src={rightQuot}/>
        </section>



        {/* <section className="section-learn">
          <div className="container">
            <div className="col-40p">
              <h2 className="listening">Learn from the best...</h2>
              <p className="listening">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p>
              <a href="#">
                <span className="text-gradient">Learn About Us</span>
                <img src={blueArrowRight}/></a>
            </div>
            <div className="col-60p">
              <div className="spread listening">
                <div className="video">
                  <img src={videoSample}/>
                  <img src={play} className="play"/>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <section className="section-learn-mobile">
          <div className="container">
            <div className="full-col">
              <a href="#">
                <span className="text-gradient">Learn About Us</span>
                <img src={blueArrowRight}/></a>
            </div>
          </div>
        </section> */}
        {/* <Testimonials/> */}
      </div>
    );
  }
  landingSlideChange(i, el) {
    this.setState({screenSlideIndex: i});
  }
}
