import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import ScrollToTop from './components/utilities/ScrollToTop';
import './styles.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(<React.Fragment>
  <BrowserRouter>
    <ScrollToTop/>
    <App/>
  </BrowserRouter>
</React.Fragment>, document.getElementById('root'));

reportWebVitals();
