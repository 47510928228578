import React, {Component} from 'react';

import Testimonial from '../Testimonials';

import financeLandingImage from '../../assets/finance-landing.png';
import arrowUpLarge from '../../assets/arrow-up-large.svg';
import solutionsImage1 from '../../assets/finance-1.png';
import solutionsImage2 from '../../assets/finance-2.png';
import solutionsImage3 from '../../assets/finance-3.png';
import solutionsImage4 from '../../assets/finance-4.png';
import productIA1 from '../../assets/product-ia-1.svg';
import productIA2 from '../../assets/product-ia-2.svg';
import productIA3 from '../../assets/product-ia-3.svg';
import productIA4 from '../../assets/product-ia-4.svg';

export default class Finance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isReady: false
    }
  }
  componentDidMount() {
    setTimeout(() => this.setState({isReady: 'ready'}), 100);
  }
  render() {
    return (
      <div className={'page-solution ' + this.state.isReady}>
        <section className="section-landing">
          <div className="container">
            <div className="col-2">
              <h1 className="offset">Ancora for finance and accounting teams</h1>
              <h5 className="offset">for faster and accurate payouts</h5>
              <p className="offset">Stay on top of your budgets and seamlessly integrate payouts with your ledger & accounts.</p>
            </div>
            <div className="col-2">
              <div className="spread offset">
                <div className="image">
                  <img src={financeLandingImage}/>
                </div>
              </div>
            </div>
          </div>
          <div className="flip-a"><img src={arrowUpLarge}/></div>
          <div className="flip-b"></div>
        </section>
        <section className="section-landing-mobile">
          <div className="container">
            <p className="listening">Stay on top of your budgets and seamlessly integrate payouts with your ledger & accounts.</p>
          </div>
        </section>
        <section className="section-laptop solution-mod">
          <div className="container">
            <div className="col-2">
              <h2 className="listening">Accurate, on-time payout processing</h2>
              <p className="listening">Improve payout accuracy & reduce payout cycle by up to 90%</p>
            </div>
            <div className="col-2">
              <div className="points">
                <div>
                  <h3 className="listening">1.</h3>
                  <p className="listening">Eliminate the risk of over-payments and delayed payments through automated commission calculation, and ensure 100% fraud and error free payouts,.</p>
                </div>
                <div>
                  <h3 className="listening delay-0">2.</h3>
                  <p className="listening delay-0">Ensure complete tax and audit compliance with detailed logs of all historical actions and transactions</p>
                </div>
                <div>
                  <h3 className="listening">3.</h3>
                  <p className="listening">Eliminate mundane tasks of calculating & verifying commissions, and utilise your precious resources for driving future growth</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-solution">
          <div className="container">
            <div className="full-col text-center">
              <h2 className="listening">Solutions:</h2>
              <p className="listening">for Accounting &amp; Finance Team</p>
            </div>
          </div>
          <div className="container">
            <div className="col-3">
              <img className="head listening" src={solutionsImage1}/>
            </div>
            <div className="col-3">
              <div className="content">
                <h2 className="listening text-gradient">1.</h2>
                <div>
                  <h5 className="listening">Payment Gateway</h5>
                  <p className="listening">Integrated payment gateway for seamless, one-click payments</p>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="image listening">
                <img src={productIA1}/>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="col-3">
              <img className="head listening" src={solutionsImage2}/>
            </div>
            <div className="col-3">
              <div className="content">
                <h2 className="listening text-gradient">2.</h2>
                <div>
                  <h5 className="listening">Accounting &amp; Compliance</h5>
                  <p className="listening">Don’t spend hours on reconciling your payouts data; easily integrate payouts data with your GL and accounting systems for accuracy and compliance</p>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="image listening">
                <img src={productIA2}/>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="col-3">
              <img className="head listening" src={solutionsImage3}/>
            </div>
            <div className="col-3">
              <div className="content">
                <h2 className="listening text-gradient">3.</h2>
                <div>
                  <h5 className="listening">Tax Management</h5>
                  <p className="listening">Automatd all your tax-related calculations through configurable and master-driven tax calculations. Ensure 100% compliance.</p>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="image listening">
                <img src={productIA3}/>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="col-3">
              <img className="head listening" src={solutionsImage4}/>
            </div>
            <div className="col-3">
              <div className="content">
                <h2 className="listening text-gradient">4.</h2>
                <div>
                  <h5 className="listening">Budgeting &amp; Forecasting</h5>
                  <p className="listening">Define budgets, analyse historical trends and set smart alerts to eliminate budget overshoots</p>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="image listening">
                <img src={productIA4}/>
              </div>
            </div>
          </div>
        </section>
        <Testimonial/>
      </div>
    )
  }
}
